<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> Edit User <small>(Fields in red are required)</small></h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label text-danger">Name</label>
								<div class="col-sm-10">
									<input type="text" v-model="accountRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputPhone" class="col-sm-2 col-form-label">Phone</label>
								<div class="col-sm-10">
									<input type="text" v-model="accountRequest.phone" @blur="validationRule()"
										class="form-control" id="inputPhone">
								</div>
							</div>
							<div class="form-group row">
								<label for="inputEmail" class="col-sm-2 col-form-label text-danger">Email</label>
								<div class="col-sm-10">
									<input type="email" v-model="accountRequest.email" @blur="validationRule()"
										class="form-control" id="inputEmail">
									<small class="text-danger">{{validationData.email}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputPoint" class="col-sm-2 col-form-label">Point</label>
								<div class="col-sm-10">
									<input type="text" v-model="accountRequest.point" @blur="validationRule()"
										class="form-control" id="inputPoint">
								</div>
							</div>
							<div class="form-group row">
								<label for="inputPassword" class="col-sm-2 col-form-label text-danger">Password</label>
								<div class="col-sm-10">
									<input type="password" v-model="accountRequest.password" @blur="validationRule()"
										class="form-control" id="inputPassword">
									<small class="text-danger">{{validationData.password}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputConfirmPassword" class="col-sm-2 col-form-label text-danger">Re-Password</label>
								<div class="col-sm-10">
									<input type="password" v-model="accountRequest.confirm" @blur="validationRule()"
										class="form-control" id="inputConfirmPassword">
									<small class="text-danger">{{validationData.confirm}}</small>
								</div>
							</div>
							<div class="form-group row" style="display: none;">
								<label for="inputImage" class="col-sm-2 col-form-label">Image <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage">
									<img v-if="accountRequest.image" v-bind:src="accountRequest.image" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image}}</small>
								</div>
							</div>

							<div class="form-group row" style="display: none;">
								<label class="col-sm-2 col-form-label"> Current Image </label>
								<div class="col-sm-10">
									<img v-if="currentImage" :src="env.mediaUrl+currentImage" class="banner-image mt-2"/>
								</div>
							</div>
				
							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'accountEdit',
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				filter: {
					page: "",
                    reverse: "",
                    sort: "",
                    name: "",
					fromDate: "",
                    toDate: ""
				},
				departmentList : [],
				roleList : [],
				currentImage: "",
				permissionList : [],
				permissionDev: false,
				checkGroupName: [],
				checkPermissionList:  [],
				accountRequest: {
					id: "",
					name: "",
					phone: "",
					email: "",
					point: "",
					image: "",
					password: "",
					roleName: '',
					checkPermissionList:  []
				},
				validationData: {
					name: "",
					email: "",
					password: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.accountRequest.id = to.params.id
					await this.accountDetail()
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				memberUpdateAction: 'memberUpdateAction',
				memberFieldsAction: 'memberFieldsAction',
				permissionListAction: 'permissionListAction',
				roleListAction: 'roleListAction'
			}),
			back() {
				this.$router.replace({ path: 'member-list', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, name: this.filter.name, fromDate: this.filter.fromDate, toDate: this.filter.toDate} }).catch(()=>{})
			},
			selectAll: function(selectPermission, selectGroup) {
				let check = this.checkGroupName.includes(selectGroup)
				if(check){
					selectPermission.forEach(el => {
						if( this.accountRequest.checkPermissionList.includes(el.permission_name_slug)){
							var index = this.accountRequest.checkPermissionList.indexOf(el.permission_name_slug);
							if (index !== -1) this.accountRequest.checkPermissionList.splice(index, 1);
						}
					});
					var indexGroup = this.checkGroupName.indexOf(selectGroup);
					if (indexGroup !== -1) this.checkGroupName.splice(indexGroup, 1);

				}else{
					selectPermission.forEach(el => {
						if( !this.accountRequest.checkPermissionList.includes(el.permission_name_slug)){
							this.accountRequest.checkPermissionList.push(el.permission_name_slug);
						}
					});
					this.checkGroupName.push(selectGroup);
				}

			},
			async getFields(){
				let option = {
					fields: {id: this.accountRequest.id, type: "update"}
				}
				await this.memberFieldsAction({
					...option
				}).then(res => {
					this.departmentList = res.data.data.department
					this.roleList = res.data.data.role
					this.accountRequest.id = res.data.data.account.id
					this.accountRequest.roleId = res.data.data.account.role
					this.accountRequest.name = res.data.data.account.name
					this.accountRequest.email = res.data.data.account.email
					this.accountRequest.phone = res.data.data.account.extra_phone
					this.currentImage = res.data.data.account.image
					this.selectedRole()
				}).catch(err => this.isLoading = true)
			},
			async selectedRole(){
				console.log(this.accountRequest.roleId)
				if(this.accountRequest.roleId == ''){
					this.permissionDev = false
				}else{
					if(this.accountRequest.roleId == "create-permission"){
						let arrLength = this.accountRequest.checkPermissionList.length
						this.accountRequest.checkPermissionList.splice(0, arrLength)
						this.accountRequest.roleName = ''
						this.permissionDev = true
					}else{
						this.permissionDev = true

						let option = {
							role: this.accountRequest.roleId
						}
						await this.roleListAction({
							...option
						}).then(res => {
							this.accountRequest.checkPermissionList = res.data.data.permissions
							this.accountRequest.roleName = res.data.data.role
						}).catch(err => this.isLoading = true)

					
					}
					}

			},
			readImage({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.accountRequest.image = e.target.result
				};
			},
			async getPermission(){
				await this.permissionListAction().then(res => {
					this.permissionList = res.data.data
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.accountRequest
				await this.memberUpdateAction({
					...option
				}).then(res => {
					this.back()
				}).catch(err => this.isLoading = true)
			},
			validationRule() {
				let isValidation = []
				if (this.accountRequest.roleId == "") {
					this.validationData.roleId = "The role field is required."
					isValidation.push(true)
				} else {
					this.validationData.roleId = ""
					isValidation.push(false)
				}

				if (this.accountRequest.name == "") {
					this.validationData.name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.accountRequest.email == "") {
					this.validationData.email = "The email field is required."
					isValidation.push(true)
				} else {
					this.validationData.email = ""
					isValidation.push(false)
				}
				if(this.accountRequest.id == ""){
                    if(this.accountRequest.password == ""){
                        this.validationData.password = "The password field is required."
                        isValidation.push(true)
                    }else{
                        this.validationData.password = ""
                        isValidation.push(false)
                    }
                    if(this.accountRequest.confirm == ""){
                        this.validationData.confirm = "The re-passwored field is required."
                        isValidation.push(true)
                    }else{
                        this.validationData.confirm = ""
                        isValidation.push(false)
                    }
                    if(this.accountRequest.password != this.accountRequest.confirm){
                        this.validationData.confirm = "The password field does not match."
                        isValidation.push(true)
                    }else{
                        this.validationData.confirm = ""
                        isValidation.push(false)
                    }
                }
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
            this.filter.name = this.$route.query.name
			this.filter.fromDate = this.$route.query.fromDate
            this.filter.toDate = this.$route.query.toDate
			this.accountRequest.id = this.$route.query.id
			this.getFields()
			this.getPermission()
		}
	}
</script>

<style scoped lang="scss">
/* Style to center grid column */
    .col-centered{
        float: none;
        margin: 0 auto;
    }
    
    /* Some custom styles to beautify this example */
    // .row{
    //     background: #dbdfe5;
    // }
    .demo-content{
        padding: 25px;
        font-size: 18px;
        background: #abb1b8;
    }
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>